<template>
  <div>
    <div
      v-if="isAuthorised"
      class="relative flex-grow w-full max-w-lg px-4 py-8 mx-auto"
    >
      Signing out...
    </div>
    <div
      v-else
      class="relative flex-grow w-full max-w-lg px-4 py-8 mx-auto"
    >
      <h1 class="text-2xl md:text-3xl xl:text-4xl">
        You’re signed out
      </h1>
      <p>
        <router-link
          :to="{ name: 'sign-in' }"
          class="block text-base"
        >
          <span class="font-medium cta-link">Sign back in</span>
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  page() {
    return {
      title: 'Sign Out',
      meta: [
        { name: 'robots', content: 'noindex' }
      ]
    }
  },

  computed: {
    ...mapGetters({
      isAuthorised: 'employers/isAuthorised'
    })
  },

  mounted() {
    if (this.isAuthorised) {
      this.signOut()
    }
  },

  methods: {
    signOut() {
      this.$store.dispatch('employers/signOut')
        .then(() => {
          window.location.reload()
        })
    }
  }
}
</script>
